import React, { useEffect, useState, useRef, useContext } from "react";
import { MediaContext } from "../store/context/media-context";
import Layout from "../layouts/LayoutCn";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import IntroContainer from "../containers/cn/IntroContainer";
import Rating from "../components/cn/main/Rating";
import Brands from "../components/cn/main/Brands";
import Setting from "../components/cn/main/Setting";
import Countries from "../components/cn/main/Countries";
import SectionEmailCollect from "../components/cn/main/SectionEmailCollect";
import Tools from "../components/cn/main/Tools";
import Testimonials from "../components/common/Testimonials";
import SectionRatingReview from "../components/cn/main/SectionRatingReview";
import { handleCheckLicenseForm, isSavedUserInfo } from "../helpers";
import CustomizableContainer from "../containers/Paywalls/Customizable/CustomizableContainer";
import { PAGE_OPTIONS, PAGES } from "../helpers/constants";
import SectionFive from "../components/cn/main/SectionFive";
import DealsBannerContainer from "../containers/common/DealsBannerContainer";
import DealsCN from "../components/cn/main/DealsCN";
import "../components/cn/main/index.scss";

const PgCMain = () => {
  const [isPaid, setIsPaid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpacityHomeIntro, setIsOpacityHomeIntro] = useState(true);
  const [isOpacity, setIsOpacity] = useState(true);
  const [isSecondStateCollectEmail, setIsSecondStateCollectEmail] =
    useState(false);
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  const [isCnUser, setIsCnUser] = useState(false);
  const [isShowCnVpnModal, setIsCnShowVpnModal] = useState(false);
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");
  const [redirectUrl, setRedirectUrl] = useState(
    PAGE_OPTIONS[PAGES.CN].INTRO_REDIRECTED_URL.EN
  );
  const extensionRef = useRef(null);
  const pricingRef = useRef(null);
  const listRef = [pricingRef, extensionRef];

  const media = useContext(MediaContext);
  const closeYoutubeModal = () => setUrlYoutubeModal("");

  const checkStatusUser = (isCnUser) => {
    if (isCnUser) {
      setRedirectUrl(PAGE_OPTIONS[PAGES.CN].INTRO_REDIRECTED_URL.CN);
    }
  };

  const openCnVpnModal = () => {
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
    setIsOpacity(true);
    setIsCnShowVpnModal(true);
  };

  const closeCnVpnModal = () => {
    document.getElementsByTagName("html")[0].style.overflow = "auto";
    setIsCnShowVpnModal(false);
    setIsOpacity(false);
  };

  useEffect(() => {
    let mounted = true;
    if (isSavedUserInfo() && mounted) {
      setIsSecondStateCollectEmail(true);
      handleCheckLicenseForm(setIsPaid, setIsOpacityHomeIntro);
    }
    setIsLoading(false);
    if (!isSavedUserInfo() && mounted) {
      setIsOpacityHomeIntro(false);
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    checkStatusUser(isCnUser);
  }, [isCnUser]);

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  return (
    <Layout
      closeCnVpnModal={closeCnVpnModal}
      customClass="PgCMain"
      isShowCnVpnModal={isShowCnVpnModal}
      tariffsElement={pricingRef}
      closeYoutubeModal={closeYoutubeModal}
      urlYoutubeModal={urlYoutubeModal}
      isShowYoutubeModal={isShowYoutubeModal}
      isSecondStateCollectEmail={isSecondStateCollectEmail}
      setIsOpacity={setIsOpacity}
      isOpacity={isOpacity}
      setIsCnUser={setIsCnUser}
      isCnUser={isCnUser}
      isSimpleTopPanel
      isCnPage={true}
      listRef={listRef}
      isShowLanguageSwitcher
    >
      <Seo
        title="AMZ侦探：最准确的产品数据分析和选品工具"
        description="通过AMZ侦探，使用精准的调查工具和卖方软件（AMZ侦探 PRO Chrome 扩展、产品数据库、关键字搜索等）来简化对亚马逊产品的研究。分析利基市场，找到最佳产品来获利。开始免费试用。"
        page="cn"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <IntroContainer
        isPaid={isPaid}
        setIsPaid={setIsPaid}
        isLoading={isLoading}
        isSecondStateCollectEmail={isSecondStateCollectEmail}
        setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
        setIsOpacity={setIsOpacityHomeIntro}
        isOpacity={isOpacityHomeIntro}
        redirectUrl={redirectUrl}
      />
      <Rating />
      <Brands />
      <Setting/>
      <Countries
        isMobile={media.isNotInitMobile}
        extensionRef={extensionRef}
        id="extension_referrer"
      />
      <SectionFive
        handleClick={openCnVpnModal}
        isSecondStateCollectEmail={isSecondStateCollectEmail}
        setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
        isMobile={media.isNotInitMobile}
        isLoading={isLoading}
        isCnUser={isCnUser}
        isOpacity={isOpacity}
      />
      <CustomizableContainer
        pricingRef={pricingRef}
        customClass="PgCMain-Pricing"
        language="cn"
        subTitlePro
      />
      <Tools />
      <Testimonials
        setUrlYoutubeModal={setUrlYoutubeModal}
        title="授权知名卖家与YouTube博主"
        isSwiper
        isCnPage
        isMainPage
      />
      <SectionRatingReview isMobile={media.isNotInitMobile} />
      <SectionEmailCollect
        isSecondStateCollectEmail={isSecondStateCollectEmail}
        setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
        isMobile={media.isNotInitMobile}
        isLoading={isLoading}
        redirectUrl={redirectUrl}
        redirect
        buttonTextSecondState="免费试用AMZ侦探!"
      />
      <DealsBannerContainer
        dealsUrl={process.env.DEALS_CN_URL}
        dealsVersion={process.env.DEALS_CN_VERSION}
        dealsSection={process.env.DEALS_CN_SECTION}
        category="Index"
        label="Get Holiday Deals!"
      >
        <DealsCN />
      </DealsBannerContainer>
    </Layout>
  );
};
export default PgCMain;
